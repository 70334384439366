.lk-menu {
  display: flex;
  margin-top: 16px;
  &__tab {
    background-color: #1976d2;
    padding: 30px 70px;
    margin-right: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    &__link {
      height: 40px !important;
      width: 40px !important;
    }
  }
  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
  &__tab:hover {
    opacity: 0.8;
  }
}

@media (max-width: 576px) {
  .lk-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 16px;

    &__tab {
      padding: 20px 0;
      width: auto;
      margin-right: 0;
      &__link {
        height: 30px !important;
        width: 30px !important;
      }
    }
    a {
      font-size: 14px;
    }
  }
}
