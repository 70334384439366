.card-detail {
  &__params {
    display: flex;
    margin-bottom: 5px;
    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #97a9b7;
    }
    &__value {
      margin-right: 15px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #002e52;
      padding-left: 15px;
    }
  }
  &__fuels {
    margin-top: -16px;
    display: flex;
  }
}
.home {
  margin-bottom: 30px;
  &__widgets {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    &__block {
      background: #1688da;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: right;
      padding: 20px;
      margin: 0 10px;
      min-width: 180px;
      &__value {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: white;
        padding-bottom: 10px;
      }
      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: white;
      }
    }
  }
  &__working-block {
    border: 2px dashed #97a9b7;
    border-radius: 8px;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-default {
      width: 250px !important;
    }
  }
}

.transaction-table {
  &__head {
    display: flex;
    margin-bottom: 20px;
    > div {
      flex: 1;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #97a9b7;
    }
  }
  &__body {
    box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
    border-radius: 8px;
    padding: 20px 0px;
    margin-bottom: 10px;
    display: flex;
    > div {
      flex: 1;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #002e52;
      padding-left: 15px;
    }
  }
}
.card-detail {
  margin-bottom: 32px;
}
.transactions-mobile {
  display: none;
}

.barcode {
  display: none;
  margin-bottom: 15px;
  box-shadow: 0px 1px 16px rgb(0 46 82 / 10%);
  padding: 13px;
  border-radius: 8px;
  align-items: center;
}
.block-buttons {
  display: flex;
  .btn-default {
    width: 150px !important;
    margin-right: 16px;
  }
}
@media (max-width: 992px) {
  .barcode {
    display: flex;
  }
  .transaction-table {
    display: none;
  }
  .card-detail {
    &__fuels {
      flex-wrap: wrap;
      min-height: 100%;
      > div {
        margin-top: 20px;
      }
      .home__widgets__block {
        width: 100%;
        margin: 0;
        margin-top: 16px;
      }
    }
  }

  .transactions-mobile {
    display: flex;
    flex-direction: column;
    &__block {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px;
      &__head {
        display: flex;
        align-items: center;
        > div:last-child {
          margin-left: auto;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 22px;
          color: #97a9b7;
        }
        > div:first-child {
          margin-right: 15px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #002e52;
          p {
            margin-top: 0;
            margin-bottom: 0;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 22px;
            color: #97a9b7;
          }
        }
      }
      &__body {
        display: flex;
        margin-top: 8px;
        > div {
          margin-top: 0;
          margin-bottom: 0;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #002e52;
          padding-left: 3px;
        }
      }
    }
  }
}
