.back {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 16px;
}
.back:hover {
  opacity: 0.7;
}
