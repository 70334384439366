.report-protocol {
  &__filter {
    margin-top: 16px;
    .na-filter__fields {
      display: flex;
      flex-direction: row;
      &__field {
        width: 300px;
        margin-right: 16px;
      }
    }
    .na-filter {
      &__buttons {
        display: flex;
        button {
          width: 180px !important;
          margin-right: 16px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .report-protocol {
    .na-filter__fields {
      flex-direction: column;
      width: 100%;
      &__field {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
