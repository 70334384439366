.header {
  &__panel {
    border-bottom: 1px solid var(--color-ui-border);
    display: flex;
    padding: 16px 140px;
    &__elem {
      margin-left: auto;
      display: flex;
      align-items: center;
      &__exit {
        padding-left: 10px;
        cursor: pointer;
        color: var(--color-text);
        transition: 0.3s;
      }
      &__exit:hover {
        opacity: 0.7;
      }
      img {
        padding-right: 10px;
      }
    }
  }
  &__menu {
    display: flex;
    padding: 8px 140px;
    box-shadow: 0px 2px 8px rgba(0, 46, 82, 0.08);
    &__link {
      text-decoration: none;
      margin: 0 10px;
      padding: 8px 10px;
      color: var(--color-text);
      display: flex;
      align-items: center;
      transition: 0.3s;
    }
    &__link:hover {
      opacity: 0.7;
    }
  }
}
.button-working {
  margin-right: 20px;
  cursor: pointer;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
}

@media (max-width: 540px) {
  .header {
    &__panel {
      padding: 16px;
    }
  }
}
