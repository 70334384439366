.home {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  &__card {
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
    }
    &__text {
      position: absolute;
      bottom: 20px;
      left: 20px;
      color: #d4ccc9;
      font-size: 16px;
      line-height: 22px;
    }
    &__status {
      position: absolute;
      right: 20px;
      top: 15px;
      background-color: #2adba8;
      font-size: 14px;
      line-height: 20px;
      padding: 3px 10px;
      color: #ffffff;
      font-weight: 600;
      border-radius: 4px;
    }
    &__number {
      width: 180px;
      position: absolute;
      left: calc(50% - 90px);
      padding: 5px 0;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 4px;
      top: calc(50% - 14.5px);
      text-align: center;
      color: black;
      font-weight: bold;
    }
  }
  &__card:hover {
    opacity: 0.7;
  }
}
.custom-card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 14px 16px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  min-height: 150px;
  &__blocked {
    opacity: 0.5;
  }
  &__logo {
    img {
      height: 20px;
    }
  }
  &__number {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: var(--color-text);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 22px;
    margin-top: 22px;
  }
  &__block-balance {
    &__fuels {
      // display: flex;
      flex-wrap: wrap;
    }
    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: var(--color-text-opacity);
    }
    &__value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-text);
      // border-right: 1px solid var(--color-text-opacity);
      padding-right: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    &__value:last-child {
      border-right: none;
    }
  }
  &__custom-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--color-text-opacity);
    margin-top: 17px;
    display: flex;
    flex-direction: column;
  }
}
.custom-card:hover {
  opacity: 0.7;
}

.list-card-filter {
  display: flex;
  &__block-button {
    display: flex;
    max-width: 400px;
    > button {
      margin-right: 15px;
    }
    margin-bottom: 32px;
  }
  &__field {
    width: 350px;
    margin-right: 16px;
  }
}

@media (max-width: 1440px) {
  .home {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .home {
    grid-template-columns: repeat(3, 1fr);
  }
  .custom-card {
    &__number {
      font-size: 16px;
      line-height: 16px;
      margin-top: 14px;
      margin-bottom: 14px;
    }
    &__custom-name {
      font-size: 14px;
      margin-top: 4px;
    }
    &__block-balance {
      &__value {
        font-size: 12px;
      }
    }
  }
  .list-card-filter {
    &__field {
      width: 270px;
      margin-right: 16px;
    }
  }
}
@media (max-width: 720px) {
  .home {
    grid-template-columns: repeat(2, 1fr);
  }
  .custom-card {
    &__number {
      font-size: 16px;
      line-height: 16px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    &__custom-name {
      font-size: 14px;
      margin-top: 12px;
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
  .list-card-filter {
    &__field {
      width: 200px;
      margin-right: 16px;
    }
  }
}
@media (max-width: 576px) {
  .home {
    grid-template-columns: repeat(1, 1fr);
  }
  .list-card-filter {
    flex-direction: column;
    .mui-input:first-child {
      margin-bottom: 8px;
    }
    &__field {
      width: 100%;
      margin-right: 0;
    }
  }
  .list-card-filter__block-button {
    margin-top: 16px;
  }
  .title-component {
    margin: 10px 0px;
  }
  .large-text {
    font-size: 22px;
  }
}
