.change-password-button {
  margin-top: 20px;
  display: flex;
  max-width: 300px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 16px rgb(0 46 82 / 10%);
  padding: 13px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
}
.change-password-button:hover {
  opacity: 0.8;
}
.change-password-form {
  &__block-buttons {
    display: flex;
    .btn-default {
      width: 150px !important;
      margin-right: 16px;
    }
  }
  &__success-form {
    .btn-default {
      width: 150px !important;
      margin-right: 16px;
    }
  }
}

@media (max-width: 992px) {
  .change-password-button {
    display: flex;
    max-width: 100%;
  }
}
